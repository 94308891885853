import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card, Button, Divider, CardContent, Box, Grid,
} from '@mui/material';
import Swal from 'sweetalert2';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { addDaysFromString, formatDateToSpanish, getToday } from '../../auxiliares/fechas';
import Iconify from '../../componentes/Iconify';
import RDateField from '../../componentes/forms/inputs/RDateField';
import {
  buildBody,
  dosDecimales,
  getUniqueKey,
  isEmpty,
  roundHalf,
  workWithLocalStorage,
} from '../../auxiliares/funciones';
import CONFIG from '../../CONFIG.json';
import RTextField from '../../componentes/forms/inputs/RTextField';
import RTextArea from '../../componentes/forms/inputs/RTextArea';
import { getTiposPago, getTrabajadores } from '../../auxiliares/maestros';
import RSelect from '../../componentes/forms/inputs/RSelect';
import Recuadro from '../../componentes/Recuadro';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CierreAlta(props){
  const gastoF = {idTipoPago:0,importe:0,descripcion:''}
  const usuario = workWithLocalStorage('get','usuario',null);
  const [cargado, setCargado] = React.useState(false);
  const [fecha, setFecha] = React.useState(props.params.fecha !== undefined ? props.params.fecha : getToday('date'));
  const [trabajadores, setTrabajadores] = React.useState([]);
  const [tiposPago, setTiposPago] = React.useState([]);
  const [datos, setDatos] = React.useState({});
  const [notas, setNotas] = React.useState('');
  const [detalle, setDetalle] = React.useState([]);
  const [gasto, setGasto] = React.useState(gastoF);
  const [ingresos, setIngresos] = React.useState([]);
  const [gastos, setGastos] = React.useState([]);
  const [notasKey, setNotasKey] = React.useState(getUniqueKey());
  const [resumen, setResumen] = React.useState({hay:0, debe:0});


  React.useEffect(() => {
        async function cargaInicial() {
            if (!cargado) {
                setTrabajadores(await getTrabajadores(usuario.token));
                setTiposPago(await getTiposPago(usuario.token));
                const bodyJson = JSON.stringify(buildBody(usuario.token,{ fecha }))
                fetch(`${CONFIG.api}cierre/detalle`,{
                            method: 'POST',
                            body: bodyJson,
                            headers: {'Content-Type': 'application/json'}
                    })
                    .then(res => res.json())
                    .then((result) => {
                      if (result.error === 0) {
                          setDatos(result.data.datos);
                          setDetalle(result.data.detalle);
                          calculaTotales(result.data.detalle);
                          setGastos(result.data.gastos);
                          setIngresos(result.data.ingresos);
                      }
                      props.showLoader(false);
                      setCargado(true);
                      setNotasKey(getUniqueKey())
                })
            }
        }
        cargaInicial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cargado]);

  const calculaTotales = (lista) =>{
    const totalHay = roundHalf(lista.reduce((sum, item) => sum + item.hay, 0));
    const totalDebe = roundHalf(lista.reduce((sum, item) => sum + item.debe, 0));
    const nuevoResumen = { hay: totalHay, debe: totalDebe };
    setResumen(nuevoResumen);
  }

  const actualizaCampo = (input) =>{
      const nombre = input.target.name
      const valor = input.target.value
      if(nombre === 'notas'){
        setNotas(valor)
        datos[nombre] = valor
        setDatos(datos)
      }else if(nombre === 'idTrabajador' || nombre === 'monedas' || nombre === 'sobre'){
        datos[nombre] = valor
        setDatos(datos)
      }else{
        detalle.find(e => e.idTipoPago.toString() === nombre).hay = Number(valor)
        setDetalle(detalle)
      }
  }

  const actualizaCampoG = (input) =>{
      const nombre = input.target.name
      const valor = input.target.value
      gasto[nombre] = valor
      setGasto(gasto)
  }

  const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true);
      setCargado(false)
  }

  const actualizaFecha = (input) =>{
      if(input.target.value !== '') {
        setFecha(input.target.value);
        Actualiza();
      }
  }

  const modFecha = (cant) =>{
      setFecha(addDaysFromString(fecha,cant));
      Actualiza();
  }

  const GrabarCierre = () =>{
        // eslint-disable-next-line react/prop-types
        datos.fecha = fecha
        datos.notas = notas
        if(datos.idTrabajador !== 0 && datos.monedas !== 0) {
          props.showLoader(true);
          const body = { datos, detalle };
          const bodyJson = JSON.stringify(buildBody(usuario.token, body))
          fetch(`${CONFIG.api}cierre/detalle`, {
            method: 'PUT',
            body: bodyJson,
            headers: { 'Content-Type': 'application/json' }
          })
            .then(res => res.json())
            .then((result) => {
              let msg = 'Cierre grabado!';
              if (result.error !== 0) {
                msg = result.errorMessage;
              }else{
                Actualiza()
              }
              Swal.fire({
                title: msg,
              })
              props.showLoader(false);
            })
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

  const GrabarNotas = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = { id: datos.id, notas };
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}cierre/notas`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            let msg ='Anotación grabada!';
            if (result.error !== 0) {
                msg = result.errorMessage;
            }
            Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
            })
            props.showLoader(false);
        })
    }

  const GrabarGasto = () =>{
        // eslint-disable-next-line react/prop-types
        if(gasto.idTipoPago !== 0 && gasto.importe !== 0&& gasto.descripcion !== '') {
          gasto.id = datos.id
          props.showLoader(true);
          const bodyJson = JSON.stringify(buildBody(usuario.token, gasto))
          fetch(`${CONFIG.api}cierre/gasto`, {
            method: 'PUT',
            body: bodyJson,
            headers: { 'Content-Type': 'application/json' }
          })
            .then(res => res.json())
            .then((result) => {
              if (result.error !== 0) {
                Swal.fire({
                  title: result.errorMessage,
                })
              }else{
                Actualiza()
              }
              props.showLoader(false);
            })
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

  const borrarGasto = (id) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = { id };
        const bodyJson = JSON.stringify(buildBody(usuario.token, body))
        fetch(`${CONFIG.api}cierre/gasto`, {
          method: 'POST',
          body: bodyJson,
          headers: { 'Content-Type': 'application/json' }
        })
          .then(res => res.json())
          .then((result) => {
            if (result.error !== 0) {
                Swal.fire({
                  title: result.errorMessage,
                })
              }else{
                Actualiza()
              }
            props.showLoader(false);
          })
    }

  const EnviarCierre = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = { fecha };
        const bodyJson = JSON.stringify(buildBody(usuario.token, body))
        fetch(`${CONFIG.api}cierre/envio`, {
          method: 'POST',
          body: bodyJson,
          headers: { 'Content-Type': 'application/json' }
        })
          .then(res => res.json())
          .then((result) => {
            let msg ='Informe enviado!';
            if (result.error !== 0) {
              msg = result.errorMessage;
            }else if(result.enviado === 0){
              msg = 'No se pudo enviar el informe';
            }
            Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
            })
            props.showLoader(false);
          })
    }


  return (
    <Box>
      { cargado &&
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardContent sx={{ paddingBottom: 0 }}>
            <Grid container>
              <Grid item sm={1} sx={{textAlign:'right'}}>
                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>modFecha(-1)}>{'<'}</Button>
              </Grid>
              <RDateField key={fecha} name="fecha" readOnlyBtn label="Fecha" actualizaCampo={actualizaFecha} value={fecha} md={2} />
              <Grid item sm={1} sx={{textAlign:'left'}}>
                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>modFecha(1)}>{'>'}</Button>
              </Grid>
              <Grid item sm={4}>
                <Typography variant="h4" sx={{textAlign: 'center'}}>Cierre del {formatDateToSpanish(fecha)}</Typography>
              </Grid>
              <Grid item sm={4} sx={{textAlign:'right'}}>
                { datos.idTrabajador !== 0 &&
                <Button variant="contained" color="warning" sx={{ color: 'white', marginLeft: '15px'}} to="#" onClick={()=>EnviarCierre()}>
                  Enviar Cierre
                </Button>
                }
                <Button variant="contained" color="success" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                onClick={()=>Actualiza()}>
                  Actualizar
                </Button>
                <Button variant="contained" color="primary" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={()=>GrabarCierre()}>
                  Grabar
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <Grid container>
              <Grid item sm={4}>
                <TableContainer component={Paper}>
                  <Table aria-label="excel-like table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Tipo Pago</StyledTableCell>
                        <StyledTableCell>Debe haber en caja</StyledTableCell>
                        <StyledTableCell>Hay en caja</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody key={fecha}>
                      {detalle.map((t, index) =>
                        <StyledTableRow key={index}>
                          <StyledTableCell>{t.tipoPago}</StyledTableCell>
                          <StyledTableCell>{t.debe} €</StyledTableCell>
                          <StyledTableCell>
                            <RTextField type='Number' name={t.idTipoPago} actualizaCampo={actualizaCampo} value={t.hay}
                                        md={9} />
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Totales</StyledTableCell>
                        <StyledTableCell>{resumen.debe} €</StyledTableCell>
                        <StyledTableCell>{resumen.hay} €</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item sm={8} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
                <Grid container spacing={1} sx={{ marginBottom: '25px' }}>
                  <RSelect name="idTrabajador" label="Recepción*" actualizaCampo={actualizaCampo}
                           value={datos.idTrabajador} md={2}
                           values={trabajadores.filter(e => e.puesto === 'RECEPCIONISTA')} />
                  <RTextField type="number" name="monedas" label="Importe Monedas*" actualizaCampo={actualizaCampo}
                              value={datos.monedas} md={2} />
                  <RTextField type="number" name="sobre" label="Importe Sobre" actualizaCampo={actualizaCampo}
                              value={datos.sobre} md={2} />
                  <br />
                  <RTextArea name="notas" key={notasKey} label="Anotaciones" actualizaCampo={actualizaCampo}
                             value={datos.notas} md={10} rows={3} sx={{ marginTop: '20px'}} />
                  {datos.id &&
                    <Grid item md={2} sx={{ textAlign: 'left'}}>
                      <Button variant="contained" color="primary" sx={{ color: 'white', marginTop: '20px' }} to="#"
                              startIcon={<Iconify icon="eva:edit-fill" />}
                              onClick={() => GrabarNotas()}>
                        Grabar
                      </Button>
                    </Grid>
                  }
                </Grid>
                {datos.id &&
                <Recuadro titulo={'Gastos'}>
                  <Grid container spacing={1} sx={{ paddingTop: '15px' }}>
                    <RSelect name="idTipoPago" label="Forma de Pago*" actualizaCampo={actualizaCampoG} value={gasto.idTipoPago} md={2} values={tiposPago} />
                    <RTextField type="number" name="importe" label="Importe*" actualizaCampo={actualizaCampoG}
                                value={gasto.importe} md={2} />
                    <RTextField name="descripcion" label="Descripción*" actualizaCampo={actualizaCampoG}
                                value={gasto.descripcion} md={6} />
                    <Grid item md={2} sx={{ textAlign: 'left'}}>
                      <Button variant="contained" color="primary" sx={{ color: 'white' }} to="#"
                              startIcon={<Iconify icon="eva:edit-fill" />}
                              onClick={() => GrabarGasto()}>
                        Grabar
                      </Button>
                    </Grid>
                  </Grid>
                  { !isEmpty(gastos) &&
                    <TableContainer component={Paper} sx={{marginTop: '20px'}}>
                      <Table aria-label="excel-like table">
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell>Tipo Pago</StyledTableCell>
                            <StyledTableCell>Importe</StyledTableCell>
                            <StyledTableCell>Descripción</StyledTableCell>
                            <StyledTableCell/>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody key={gastos.length}>
                          {gastos.map((t, index) =>
                            <StyledTableRow key={index}>
                              <StyledTableCell>{t.tipoPago}</StyledTableCell>
                              <StyledTableCell>{t.importe} €</StyledTableCell>
                              <StyledTableCell>{t.descripcion}</StyledTableCell>
                              <StyledTableCell>
                                <Button variant="contained" color="error" sx={{ color: 'white' }} to="#"
                                        startIcon={<Iconify icon="eva:edit-fill" />}
                                        onClick={() => borrarGasto(t.id)}>
                                  Borrar
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                </Recuadro>
                }
                <Recuadro titulo={'Ventas'}>
                  { !isEmpty(ingresos) &&
                    <TableContainer component={Paper} sx={{marginTop: '20px'}}>
                      <Table aria-label="excel-like table">
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell>Cliente</StyledTableCell>
                            <StyledTableCell>Form. Pago</StyledTableCell>
                            <StyledTableCell>Descripción</StyledTableCell>
                            <StyledTableCell>Importe</StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {ingresos.map((t, index) =>
                            <StyledTableRow key={index}>
                              <StyledTableCell>{t.cliente}</StyledTableCell>
                              <StyledTableCell>{t.tipoPago}</StyledTableCell>
                              <StyledTableCell>{t.descripcion}</StyledTableCell>
                              <StyledTableCell>{t.precio} €</StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                </Recuadro>
            </Grid>
          </Grid>
        </CardContent>
        </Card>
      }
    </Box>
  );
};
