import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card, CardHeader, Button, Divider, CardContent, Modal, Box, Grid, Stack, TextField, InputAdornment,
} from '@mui/material';
import Swal from 'sweetalert2';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { addDaysFromString, formatDateToSpanish, getToday } from '../../auxiliares/fechas';
import Iconify from '../../componentes/Iconify';
import RDateField from '../../componentes/forms/inputs/RDateField';
import RTextArea from '../../componentes/forms/inputs/RTextArea';
import CitaForm from './form/alta';
import {
  buildBody,
  getUniqueKey,
  isEmpty,
  separarTextoYNumero,
  workWithLocalStorage,
} from '../../auxiliares/funciones';
import CONFIG from '../../CONFIG.json';
import { getClientes } from '../../auxiliares/maestros';
import RSelect from '../../componentes/forms/inputs/RSelect';
import Recuadro from '../../componentes/Recuadro';
import Huecos from './form/huecos';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

const blueRow = {
    backgroundColor: '#00B0F0 !important',
  };
const greenRow = {
    backgroundColor: '#92D050 !important',
  };
const pinkRow = {
    backgroundColor: '#FF66FF !important',
  };
const brownRow = {
    backgroundColor: '#ED7D31 !important',
  };
const whiteCell = {
    color: 'white !important',
  };
const boldRow = {
    fontWeight: 800,
  };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function CitasDia(props){
  const usuario = workWithLocalStorage('get','usuario',null);
  const [cargado, setCargado] = React.useState(false);
  const [trabajadoresDisp, setTrabajadoresDisp] = React.useState([]);
  const [cita, setCita] = React.useState(0);
  const [citas, setCitas] = React.useState([]);
  const [huecos, setHuecos] = React.useState([]);
  const [citasShow, setCitasShow] = React.useState([]);
  const [citasBusq, setCitasBusq] = React.useState({text:'', trabajador: 0, sala: 0});
  const [clientes, setClientes] = React.useState([]);
  const [resumen, setResumen] = React.useState({ citas: 0, citasconf: 0, caja: 0});
  const [fecha, setFecha] = React.useState(getToday('date'));
  const [notas, setNotas] = React.useState('');
  const [notasKey, setNotasKey] = React.useState(getUniqueKey());
  const [trabsKey, setTrabsKey] = React.useState(getUniqueKey());
  const [modalOpen, setModalOpen] = React.useState(false);
  const cierraModal = () => setModalOpen(false);
  const abreModal = () => setModalOpen(true);

  const campos = ["hora","horafin","estadoConf","sala","idTrabajador","idTrabajador2","recepcion","nombreCli","telefono","servicio","tipoPagoNombre","duracion","valorservicio","notas","acompanamiento","aceite"]

  React.useEffect(() => {
        async function cargaInicial() {
            if (!cargado) {
                setClientes(await getClientes(usuario.token));
                const bodyJson = JSON.stringify(buildBody(usuario.token,{ fecha }))
                fetch(`${CONFIG.api}list/citas`,{
                            method: 'POST',
                            body: bodyJson,
                            headers: {'Content-Type': 'application/json'}
                    })
                    .then(res => res.json())
                    .then((result) => {
                      if (result.error === 0) {
                        if (!isEmpty(result.data.citas)) {
                          setCitas(result.data.citas);
                          setCitasShow(result.data.citas);
                          refreshResumen(result.data.citas)
                        } else {
                          setCitas([]);
                          setCitasShow([]);
                          refreshResumen([])
                        }
                        setTrabajadoresDisp(result.data.trabs);
                        setHuecos(result.data.huecos);
                        setTrabsKey(getUniqueKey())
                      }
                      props.showLoader(false);
                      setCargado(true);
                })
              const bodyJson2 = JSON.stringify(buildBody(usuario.token,{ fecha }))
              fetch(`${CONFIG.api}citas/notas`,{
                            method: 'POST',
                            body: bodyJson2,
                            headers: {'Content-Type': 'application/json'}
                    })
                    .then(res => res.json())
                    .then((result) => {
                      if(isEmpty(result)){
                        setNotas('')
                      }else {
                        setNotas(result.notas);
                      }
                      setNotasKey(getUniqueKey())
                })
            }
        }
        cargaInicial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cargado]);

  const refreshResumen = (data) =>{
    if(isEmpty(data)){
      setResumen({ citas: 0, citasconf: 0, caja: 0, citasreal: 0})
    }else{
      resumen.citas = data.length
      resumen.citasconf = data.filter(c => c.estadoConf === 'CF').length
      resumen.citasreal = data.filter(c => c.realizado === 1).length
      // const pagos = data.filter(c => c.contabilidad === 1)
      // eslint-disable-next-line
      resumen.caja = data.reduce((sum, item) => sum += item.valorservicio, 0);
      setResumen(resumen)
    }
  }

  const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true);
      if(modalOpen){
        cierraModal();
      }
      setCargado(false)
  }

  const actualizaNotas = (input) =>{
      setNotas(input.target.value);
  }

  const actualizaFecha = (input) =>{
      if(input.target.value !== '') {
        setFecha(input.target.value);
        Actualiza();
      }
  }

  const editaCita = (input) => {
    const obj = input.target.name.split('-')
    const valor = input.target.value
    const idcita = citas[obj[1]].id
    props.showLoader(true);
        const body = {
          id: idcita,
          [obj[0]]: valor
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}cita/editar`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            props.showLoader(false);
            if (result.error === 0) {
                Actualiza()
              }else{
                Swal.fire({
                  title: result.errorMessage,
                  buttons: [{label: 'Ok'}]
                })
              }
        })
    }

  const modFecha = (cant) =>{
      setFecha(addDaysFromString(fecha,cant));
      Actualiza();
  }

  const AltaCita = (id) =>{
        props.showLoader(true);
        setCita(id);
        abreModal();
  }

  const Borrar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = {
          tabla: "cita",
          activo: 0,
          id: obj.id
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}master/activo`,{
                  method: 'PUT',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Cita borrada!';
              if (result.error === 0) {
                Actualiza()
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

  const GrabarNotas = () =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = { fecha, notas };
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}citas/notas`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            let msg ='Anotación grabada!';
            if (result.error !== 0) {
                msg = result.errorMessage;
            }
            Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
            })
            props.showLoader(false);
        })
    }

  const confirmar = (id, estado) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const estadoC = estado === 0 ? 1 : 0
        const body = { id, estado: estadoC };
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}cita/confirmar`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            const tipo = estadoC === 0 ? 'Desmarcada' : 'Realizada'
            let msg =`Cita ${tipo}!`;
            if (result.error !== 0) {
                msg = result.errorMessage;
            }else{
              Actualiza()
            }
            Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
            })
            props.showLoader(false);
        })
    }

  const buscaTabla = (input) =>{
    const value = input.target.value
    const name = input.target.name
    citasBusq[name] = value
    let datos = citas
    if(citasBusq.text !== '') {
      datos = datos.filter(item => campos.some(key =>
        String(item[key]).toLowerCase().includes(citasBusq.text.toLowerCase())
      ))
    }
    if(citasBusq.trabajador !== 0) {
      datos = datos.filter(e => e.idTrabajador === citasBusq.trabajador || e.idTrabajador2 === citasBusq.trabajador)
    }
    if(citasBusq.sala !== 0) {
      datos = datos.filter(e => e.idRoom === citasBusq.sala)
    }
    setCitasShow(datos)
    setCitasBusq(citasBusq)
  }

  return (
    <Box>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardContent sx={{ paddingBottom: 0 }}>
            <Grid container>
              <Grid item sm={1} sx={{textAlign:'right'}}>
                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>modFecha(-1)}>{'<'}</Button>
              </Grid>
              <RDateField key={fecha} name="fecha" label="Fecha" readOnlyBtn actualizaCampo={actualizaFecha} value={fecha} md={2} />
              <Grid item sm={1} sx={{textAlign:'left'}}>
                <Button variant="contained" color="success" sx={{ color: 'white'}} to="#" onClick={()=>modFecha(1)}>{'>'}</Button>
              </Grid>
              <Grid item sm={5}>
                <Typography variant="h4" sx={{textAlign: 'center'}}>Citas del {formatDateToSpanish(fecha)}</Typography>
              </Grid>
              <Grid item sm={3} sx={{textAlign:'right'}}>
                <Button variant="contained" color="success" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                onClick={()=>Actualiza()}>
                  Actualizar
                </Button>
                <Button variant="contained" color="primary" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={()=>AltaCita(0)}>
                  Nueva Cita
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <Stack direction="row" sx={{ width: '100%'}} justifyContent="space-between">
              <Grid container spacing={2}>
                <Grid item sm={2}>
                  <TextField placeholder="Buscar en la tabla" value={citasBusq.texto} onChange={(e)=>buscaTabla(e)} type="text" name="text" autoComplete="off"
                    sx={{ float: 'right', paddingBottom: '15px'}}
                    InputProps={{
                            endAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                            </InputAdornment>
                            ),
                    }}/>
                </Grid>
                <RSelect name="trabajador" label="Trabajadora" actualizaCampo={buscaTabla} value={citasBusq.trabajador} md={2}
                  values={[{id:0, nombre: ' - '}, ...props.trabajadores.filter(e=>e.puesto==='MASAJISTA')]}/>
                <RSelect name="sala" label="Sala" actualizaCampo={buscaTabla} value={citasBusq.sala} md={2}
                  values={[{id:0, nombre: ' - '}, ...props.rooms]}/>
              </Grid>
            </Stack>
            <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
              <Table aria-label="excel-like table" stickyHeader>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell/>
                    <StyledTableCell/>
                    <StyledTableCell>Hora</StyledTableCell >
                    <StyledTableCell  align="right">Hora Fin</StyledTableCell >
                    <StyledTableCell  align="right">Conf.</StyledTableCell >
                    <StyledTableCell  align="right">Sala</StyledTableCell >
                    <StyledTableCell  align="right">Trab.</StyledTableCell >
                    <StyledTableCell  align="right">Trab.2</StyledTableCell >
                    <StyledTableCell  align="right">Recep.</StyledTableCell >
                    <StyledTableCell  align="right">Cliente</StyledTableCell >
                    <StyledTableCell  align="right">Teléfono</StyledTableCell >
                    <StyledTableCell  align="right">Servicio</StyledTableCell >
                    <StyledTableCell  align="right">Tipo Pago</StyledTableCell >
                    <StyledTableCell  align="right">Duración</StyledTableCell >
                    <StyledTableCell  align="right">Importe</StyledTableCell >
                    <StyledTableCell  align="right">Comentario</StyledTableCell >
                    <StyledTableCell  align="right">Acompañamiento</StyledTableCell >
                    <StyledTableCell  align="right">Aceite</StyledTableCell >
                    <StyledTableCell/>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                    {citasShow.map((c, index)=> {
                      let colorRow = {}
                      if(c.tipoPago === 9){
                        colorRow = brownRow
                      }else if(c.tipoPago === 7 && c.bduracion === 300){
                        colorRow = greenRow
                      }else if(c.tipoPago === 7 && c.bduracion === 600){
                        colorRow = pinkRow
                      }else if(c.tipoPago === 7 && c.bduracion === 1200){
                        colorRow = blueRow
                      }
                      const colorCell = boldRow // !isEmpty(colorRow) ? whiteCell : {}
                      return <StyledTableRow key={index} sx={colorRow}>
                                <StyledTableCell align="right">
                                  <Button variant="contained" color="primary" sx={{ color: 'white' }} to="#"
                                          onClick={() => AltaCita(c.id)}>
                                    Editar
                                  </Button>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {c.realizado === 0 ?
                                  <Button variant="contained" color="success" sx={{ color: 'white' }} to="#"
                                          onClick={() => confirmar(c.id, c.realizado)} startIcon={<DoneAllIcon />}/>
                                    :
                                    <Button variant="contained" color="error" sx={{ color: 'white' }} to="#"
                                          onClick={() => confirmar(c.id, c.realizado)} startIcon={<RemoveDoneIcon />}/>
                                  }
                                </StyledTableCell>
                                {campos.map((campo, index2) => {
                                    if(campo === 'idTrabajador'){
                                      return <StyledTableCell key={`${campo}-${index2}`} align="right"
                                                              sx={colorCell}>
                                        <RSelect name={`idTrabajador-${index}`} actualizaCampo={editaCita} value={c.idTrabajador} md={12}
                                        values={[{id:null, nombre: '(Nadie)'}, ...trabajadoresDisp.filter(e=>(e.puesto==='MASAJISTA' && e.disponibilidad === ''))]} inputProps={{ IconComponent: () => null }}/>
                                      </StyledTableCell>
                                    }
                                    if(campo === 'idTrabajador2'){
                                      return <StyledTableCell key={`${campo}-${index2}`} align="right"
                                                              sx={colorCell}>
                                        <RSelect name={`idTrabajador2-${index}`} actualizaCampo={editaCita} value={c.idTrabajador2} md={12}
                                        values={[{id:null, nombre: '(Nadie)'}, ...trabajadoresDisp.filter(e=>(e.puesto==='MASAJISTA' && e.disponibilidad === ''))]} inputProps={{ IconComponent: () => null }}/>
                                      </StyledTableCell>
                                    }
                                    if(campo === 'sala'){
                                      return <StyledTableCell key={`${campo}-${index2}`} align="right"
                                                              sx={colorCell}>
                                        <RSelect name={`idRoom-${index}`} actualizaCampo={editaCita} value={c.idRoom} md={12}
                                        values={props.rooms} inputProps={{ IconComponent: () => null }}/>
                                      </StyledTableCell>
                                    }
                                      return <StyledTableCell key={`${campo}${index2}`} align="right"
                                                              sx={colorCell}>{c[campo]}</StyledTableCell>
                                  }
                                )}
                                <StyledTableCell align="right">
                                  {c.realizado === 0 ?
                                    <Button variant="contained" color="error" sx={{ color: 'white' }} to="#"
                                            onClick={() => Borrar(c)}>
                                      Borrar
                                    </Button> : ''}
                                </StyledTableCell>
                              </StyledTableRow>
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardContent>
            <Recuadro titulo={'Huecos disponibles'}>
              <Huecos key={huecos.length} Actualiza={Actualiza} huecos={huecos} showLoader={props.showLoader} fecha={fecha} trabajadores={trabajadoresDisp} rooms={props.rooms}/>
            </Recuadro>
          </CardContent>
          <CardContent>
            <Grid container>
              <Grid item sm={4}>
                <TableContainer component={Paper} sx={{ maxHeight: '350px', overflowY: 'auto'}}>
                  <Table aria-label="excel-like table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Trabajador</StyledTableCell >
                        <StyledTableCell>Horario</StyledTableCell >
                        <StyledTableCell>Nº Servs.</StyledTableCell >
                        <StyledTableCell>Nº Horas</StyledTableCell >
                      </StyledTableRow>
                    </TableHead>
                    <TableBody key={trabsKey}>
                        {trabajadoresDisp.filter(e=>e.puesto === 'MASAJISTA').map((t, index)=>
                          <StyledTableRow key={index}>
                            <StyledTableCell>{t.nombre}</StyledTableCell>
                            <StyledTableCell>{t.disponibilidad === '' ? t.horario : t.disponibilidad}</StyledTableCell>
                            <StyledTableCell>{t.servicios}</StyledTableCell>
                            <StyledTableCell>{t.horas}</StyledTableCell>
                          </StyledTableRow>
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item container sm={8}>
                <Grid item sm={6} sx={{ paddingLeft: '20px', paddingRight: '20px', textAlign: 'center'}}>
                  <RTextArea name="notas" key={notasKey} label="Anotaciones" actualizaCampo={actualizaNotas} value={notas} md={12} rows={8} />
                  <Button variant="contained" color="primary" sx={{ color: 'white', marginTop: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-fill" />}
                  onClick={()=>GrabarNotas()}>
                    Grabar
                  </Button>
                </Grid>
                <Grid item sm={3}>
                  <TableContainer component={Paper}>
                    <Table aria-label="excel-like table">
                      <TableBody key={trabsKey}>
                          {trabajadoresDisp.filter(e=>e.puesto === 'RECEPCIONISTA').map((t, index)=>
                            <StyledTableRow key={index}>
                              <StyledTableCell>{t.nombre}</StyledTableCell><StyledTableCell>{t.disponibilidad === '' ? t.horario : t.disponibilidad}</StyledTableCell>
                            </StyledTableRow>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item sm={3} sx={{ paddingLeft: '20px', textAlign: 'center'}}>
                  <TableContainer component={Paper}>
                    <Table aria-label="excel-like table">
                      <TableBody>
                          <StyledTableRow >
                            <StyledTableCell>Nº Citas</StyledTableCell><StyledTableCell align="right">{resumen.citas}</StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledTableCell>Citas Conf.</StyledTableCell><StyledTableCell align="right">{resumen.citasconf}</StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledTableCell>Citas Realiz.</StyledTableCell><StyledTableCell align="right">{resumen.citasreal}</StyledTableCell>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledTableCell>Valor Servicios</StyledTableCell><StyledTableCell align="right">{resumen.caja} €</StyledTableCell>
                          </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Modal open={modalOpen} onClose={cierraModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Card sx={style}>
                    <CardHeader title="Datos de la cita"
                    action={<>
                        <CloseIcon fontSize="inherit" onClick={() => cierraModal() }/>
                    </>}/>
                    <Divider style={{ margin: '15px'}} />
                    <CardContent sx={{ maxHeight: '600px', overflowY: 'auto' }}>
                      <CitaForm Actualiza={Actualiza} showLoader={props.showLoader} clientes={clientes} fecha={fecha} id={cita} citas={citas}
                          trabajadores={trabajadoresDisp} tiposPago={props.tiposPago} servicios={props.servicios} rooms={props.rooms}/>
                    </CardContent>
                </Card>
            </Modal>
    </Box>
  );
};
